import React, { Component } from "react"

export default class GLSLCanvas extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.sandbox = null;
    this.debugStyles = {
      position: `absolute`,
    }
  }

  componentDidUpdate() {

    // Update custom uniforms 

    Object.entries(this.props.uniforms).forEach(entry => {
      const [key, value] = entry;
      console.log(key, value);
      this.sandbox.setUniform(key, value);
    });

  }
  
  componentDidMount() {

    var gl = this.canvasRef.current.getContext("webgl")
      || this.canvasRef.current.getContext("experimental-webgl");

    if (gl && gl instanceof WebGLRenderingContext) {

      import("glslCanvas").then(GlslCanvas => {

        this.sandbox = new GlslCanvas.default(this.canvasRef.current);
        this.sandbox.onError = this.errorFunc;
        var string_frag_code = `
        #ifdef GL_ES
    precision mediump float;
    #endif
    
    #define TWO_PI 6.28318530718
    
    uniform vec2 u_resolution;
    uniform float u_time;
    
    //  Function from Iñigo Quiles
    //  https://www.shadertoy.com/view/MsS3Wc
    vec3 hsb2rgb( in vec3 c ){
        vec3 rgb = clamp(abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),
                                 6.0)-3.0)-1.0,
                         0.0,
                         1.0 );
        rgb = rgb*rgb*(3.0-2.0*rgb);
        return c.z * mix( vec3(1.0), rgb, c.y);
    }
    
    void main(){
        vec2 st = gl_FragCoord.xy/u_resolution;
        vec3 color = vec3(0.0);
    
        // Use polar coordinates instead of cartesian
        vec2 toCenter = vec2(0.5)-st;
        float angle = atan(toCenter.y,toCenter.x);
        float radius = length(toCenter)*2.0;
    
        // Map the angle (-PI to PI) to the Hue (from 0 to 1)
        // and the Saturation to the radius
        color = hsb2rgb(vec3(((angle+u_time)/TWO_PI)+0.5,radius,1.0));
    
        gl_FragColor = vec4(color,1.0);
    }
        `;
  
        this.sandbox.load(this.props.frag || string_frag_code);

        // Send custom uniforms 
        const today = new Date();
        today.setHours(0, 0, 0);
        const todaySeconds = (new Date().getTime() - today.getTime())/1000;
        this.sandbox.setUniform("u_timeoffset", todaySeconds);
        console.log(todaySeconds);

        console.log(this.props.uniforms);
  
      });

    }

  }
  render(props) {
    return (
      <React.Fragment>
        <canvas ref={this.canvasRef}
          className={(this.props.class || `glslCanvas`)}
          width={(this.props.width || `800`)}
          height={(this.props.height || `800`)} />
          <code style={this.debugStyles}>{JSON.stringify(this.props.uniforms)}</code>
      </React.Fragment>
    )
  }
}