import React from "react"
//import { Link } from "gatsby"

import Seo from "../components/seo"
import GLSLCanvas from "../components/glsl-canvas"
//import GlslCanvas from "glslCanvas"

const GLSLCanvasTestPage = () => (
  <>
    <Seo title="GLSLCanvasTestPage" />

    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full">
            <div className="content">

              <h1 className="underlined">GLSLCanvasTestPage</h1>

              <div className="columns is-multiline">
                <div className="column">
                  <GLSLCanvas frag={`// Author:
// Title:

#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform float u_time;

float circle(in vec2 _st, in float _radius){
    vec2 dist = _st-vec2(0.5);
    return 1.-smoothstep(_radius+(_radius*-0.0001),
    _radius-(_radius*-0.0001),
    dot(dist,dist)*4.0);
}

void main() {
    vec2 st = gl_FragCoord.xy/u_resolution.xy;
    st.x *= u_resolution.x/u_resolution.y;
    
    vec3 color = vec3(st.x,st.y,abs(sin(u_time))) * vec3(circle(st,1.00)) - vec3(circle(st,0.0275));

	gl_FragColor = vec4( color, 1.0 );
}`} />
                </div>
                <div className="column">
                  <GLSLCanvas frag={`
    #ifdef GL_ES
precision mediump float;
#endif

#define PI 3.14159265359
#define TWO_PI 6.28318530718

uniform vec2 u_resolution;
uniform float u_time;

//  Function from Iñigo Quiles
//  https://www.shadertoy.com/view/MsS3Wc
vec3 hsb2rgb( in vec3 c ){
    vec3 rgb = clamp(abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),
                             6.0)-3.0)-1.0,
                     0.0,
                     1.0 );
    rgb = rgb*rgb*(3.0-2.0*rgb);
    return c.z * mix( vec3(1.0), rgb, c.y);
}

void main(){
    vec2 st = gl_FragCoord.xy/u_resolution;
    vec3 color = vec3(0.0);

    // Use polar coordinates instead of cartesian
    vec2 toCenter = vec2(0.5)-st;
    float angle = atan(toCenter.y,toCenter.x);
    float radius = length(toCenter)*2.0;

    // Map the angle (-PI to PI) to the Hue (from 0 to 1)
    // and the Saturation to the radius
    color = hsb2rgb(vec3(((angle+u_time)/TWO_PI)+0.5,radius,1.0));

    float y = cos((st.x - 0.5) * TWO_PI);
    float x = sin((st.y - 0.5) * TWO_PI);
    vec2 d = vec2(x,y);
    float xy = x*y;
    vec3 newcolor = step(xy,color);

    gl_FragColor = vec4(newcolor,1.0);
}
    `} />
                </div>
                <div className="column">
                  <GLSLCanvas frag={`
    #ifdef GL_ES
precision mediump float;
#endif

#extension GL_OES_standard_derivatives : enable

uniform float u_time;
uniform vec2 u_mouse;
uniform vec2 u_resolution;

mat2 rotate(float a) {
	float c = cos(a), s = sin(a);
	return mat2(c, -s, s, c);
}

void main() {
	vec2 uv = (2. * gl_FragCoord.xy - u_resolution) / u_resolution.y;
	vec3 color = vec3(0.12);
	uv.x *= dot(uv,uv)*1.2;
	uv *= rotate(uv.x+u_time * .0266);
	uv = abs(uv);
	float t = u_time *0.1;
	vec2 ar = vec2(sin(atan(uv.x, uv.y)), length(uv));
	color = mix(color, vec3(.1, 29., .9), sin(1. * (ar.y * 1. - t) + t) + (09. * ar.x - 8. * ar.y));
	color = mix(color, vec3(.6, .9, 5.), cos(5. ) * (8. * ar.y - 9. * ar.x));	
	color /= mix(color, vec3(sin(t), 9., .7), sin(7. * (ar.y * 1. - t) + t) + (.6 * ar.x - 8. * ar.y));
	gl_FragColor = vec4(color, 1.);
}
    `} />
                </div>
                <div className="column">
                  <GLSLCanvas frag={`
   #ifdef GL_ES
   precision mediump float;
   #endif
   
   #extension GL_OES_standard_derivatives : enable
   
   uniform float u_time;
   uniform vec2 u_mouse;
   uniform vec2 u_resolution;
   
   mat2 rotate(float a) {
     float c = cos(a), s = sin(a);
     return mat2(c, -s, s, c);
   }
   
   void main() {
     vec2 uv = (1.448 * gl_FragCoord.xy - u_resolution) / u_resolution.y;
     vec3 color = vec3(0.165,0.454,0.600);
     uv.x *= dot(uv,uv)*0.864;
     uv *= rotate(uv.x+u_time * -0.045);
     uv = abs(uv);
     float t = u_time *0.084;
     vec2 ar1 = vec2(sin(atan(uv.x, uv.y)), length(uv));
       vec2 ar2 = vec2(cos(atan(uv.y, uv.x)), length(uv)*1.584);
     color = mix(color, vec3(1.000,0.821,0.447), sin(1.496 * (ar1.y * 0.624 - t) + t) + (8.704 * ar2.x - 8.808 * ar1.y));
     color = mix(color, vec3(0.207,0.817,1.000), sin(t ) *(8.200 * ar2.y - 8.552 * ar2.x));	
     color *= (color, vec3(sin(t), 1.624, .7), cos(7. * (ar1.y * 1.976 - t) + u_time) + (0.096 * ar1.y - 8. * ar1.x));
     color /= (color, vec3(cos(u_time), 0.688, 0.852), cos(8.520 * (ar2.x * 1.648 - t) + t) + (5.680 * ar2.x - 10.776 * ar2.y));
       gl_FragColor = vec4(color, 1.);
   }
    `} />
                </div>
                <div className="column">
                  <GLSLCanvas frag={`
#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform float u_time;

float circle(in vec2 _st, in float _radius){
  vec2 dist = _st-vec2(0.5);
  return 1.-smoothstep(_radius+(_radius*-0.0001),
  _radius-(_radius*-0.0001),
  dot(dist,dist)*4.0);
}

float blendOverlay(float base, float blend) {
  return (blend==1.0)?blend:min(base/(1.0-blend),1.0);
}

void main() {
  vec2 st = gl_FragCoord.xy/u_resolution.xy;
  st.x *= u_resolution.x/u_resolution.y;
    
  vec3 base = vec3(0.4,0.4,1.0);
  vec3 crop = vec3(circle(st,1.00)) - vec3(circle(st,0.0275));

  vec2 p=1.0*(1.0*gl_FragCoord.xy-u_resolution)/max(u_resolution.x,u_resolution.y);
	for(int i=1;i<3;i++)
	{
		vec2 newp=p;
    float speed = 3.0; // speed control
		newp.x+=1./float(i)*sin(float(i)*p.y+u_time/(speed)+0.3*float(i))+1.0;
		newp.y+=1./float(i)*cos(float(i)*p.x+u_time/(speed)+0.3*float(i))-1.4;
		p=newp;
	}
  vec3 col=vec3(0.9,0.9*sin(1.0*p.y)+0.9,sin(p.x+p.y)*3.);
  
  vec3 blend = vec3(blendOverlay(base.r,col.r),blendOverlay(base.g,col.g),blendOverlay(base.b,col.b));

  vec3 color = blend * crop;

	gl_FragColor = vec4( color, 1.0 );
}`} />
                </div>
                <div className="column"></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default GLSLCanvasTestPage
